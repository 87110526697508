// React vendors
// @import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
// @import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import './core/components/variables';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media (max-width: 610px) {
  .rdrDefinedRangesWrapper {
    display: none !important;
  }
}

.rc-slider-disabled {
  background-color: transparent !important;
}

[data-theme='dark'] {
  .rdrDefinedRangesWrapper {
    background-color: $body-bg-dark;
    border-color: lighten($body-bg-dark, 10%);
    .rdrInputRanges {
      .rdrInputRange {
        background: $body-bg-dark;
        border-color: lighten($body-bg-dark, 10%);

        .rdrInputRangeInput {
          background: $body-bg-dark;
          border-color: lighten($body-bg-dark, 10%);
          color: white;
        }
      }
    }

    .rdrStaticRange {
      border-color: lighten($body-bg-dark, 10%);
      .rdrStaticRangeLabel {
        background: $body-bg-dark;

        &:hover {
          background: lighten($body-bg-dark, 5%);
        }
      }
    }
  }
  .rdrCalendarWrapper {
    background-color: $body-bg-dark;

    .rdrDateDisplayWrapper {
      background-color: lighten($body-bg-dark, 5%);

      .rdrDateDisplayItem {
        &.rdrDateDisplayItemActive {
          border-color: rgb(61, 145, 255);
        }
        background-color: lighten($body-bg-dark, 5%);
        border-color: lighten($body-bg-dark, 10%);
        color: white;
      }
    }

    .rdrMonthAndYearWrapper {
      .rdrNextPrevButton,
      .rdrMonthAndYearPickers {
        &:hover {
          background-color: lighten($body-bg-dark, 10%);
        }
      }

      & .rdrMonthPicker,
      & .rdrYearPicker {
        select {
          color: white;
        }
      }
    }

    .rdrMonths .rdrMonth .rdrDays {
      button.rdrDay {
        &:is(.rdrDayPassive) .rdrDayNumber span {
          color: lighten($body-bg-dark, 30%);
        }
        &:not(.rdrDayPassive) .rdrDayNumber span {
          color: white;
        }
      }
    }
  }
}
